import { default as _91_46_46_46all_93BV9dHEp826Meta } from "/Users/fahrezibayu/guestify-v2/pages/[...all].vue?macro=true";
import { default as laporanEs5mX0LpZLMeta } from "/Users/fahrezibayu/guestify-v2/pages/birthday/laporan.vue?macro=true";
import { default as indexo50AavDxSqMeta } from "/Users/fahrezibayu/guestify-v2/pages/event/index.vue?macro=true";
import { default as laporanepPKz6EcdjMeta } from "/Users/fahrezibayu/guestify-v2/pages/event/laporan.vue?macro=true";
import { default as liveOPHpd1W4LUMeta } from "/Users/fahrezibayu/guestify-v2/pages/event/live.vue?macro=true";
import { default as indexEAfqQoSxq9Meta } from "/Users/fahrezibayu/guestify-v2/pages/guest/index.vue?macro=true";
import { default as indexGFEcs3iam3Meta } from "/Users/fahrezibayu/guestify-v2/pages/index.vue?macro=true";
import { default as kehadiranl5deOIyWduMeta } from "/Users/fahrezibayu/guestify-v2/pages/laporan/kehadiran.vue?macro=true";
import { default as indexbsJIm98XtyMeta } from "/Users/fahrezibayu/guestify-v2/pages/pengguna/index.vue?macro=true";
import { default as profile6K5mR8j8ZHMeta } from "/Users/fahrezibayu/guestify-v2/pages/pengguna/profile.vue?macro=true";
export default [
  {
    name: _91_46_46_46all_93BV9dHEp826Meta?.name ?? "all",
    path: _91_46_46_46all_93BV9dHEp826Meta?.path ?? "/:all(.*)*",
    meta: _91_46_46_46all_93BV9dHEp826Meta || {},
    alias: _91_46_46_46all_93BV9dHEp826Meta?.alias || [],
    redirect: _91_46_46_46all_93BV9dHEp826Meta?.redirect,
    component: () => import("/Users/fahrezibayu/guestify-v2/pages/[...all].vue").then(m => m.default || m)
  },
  {
    name: laporanEs5mX0LpZLMeta?.name ?? "birthday-laporan",
    path: laporanEs5mX0LpZLMeta?.path ?? "/birthday/laporan",
    meta: laporanEs5mX0LpZLMeta || {},
    alias: laporanEs5mX0LpZLMeta?.alias || [],
    redirect: laporanEs5mX0LpZLMeta?.redirect,
    component: () => import("/Users/fahrezibayu/guestify-v2/pages/birthday/laporan.vue").then(m => m.default || m)
  },
  {
    name: indexo50AavDxSqMeta?.name ?? "event",
    path: indexo50AavDxSqMeta?.path ?? "/event",
    meta: indexo50AavDxSqMeta || {},
    alias: indexo50AavDxSqMeta?.alias || [],
    redirect: indexo50AavDxSqMeta?.redirect,
    component: () => import("/Users/fahrezibayu/guestify-v2/pages/event/index.vue").then(m => m.default || m)
  },
  {
    name: laporanepPKz6EcdjMeta?.name ?? "event-laporan",
    path: laporanepPKz6EcdjMeta?.path ?? "/event/laporan",
    meta: laporanepPKz6EcdjMeta || {},
    alias: laporanepPKz6EcdjMeta?.alias || [],
    redirect: laporanepPKz6EcdjMeta?.redirect,
    component: () => import("/Users/fahrezibayu/guestify-v2/pages/event/laporan.vue").then(m => m.default || m)
  },
  {
    name: liveOPHpd1W4LUMeta?.name ?? "event-live",
    path: liveOPHpd1W4LUMeta?.path ?? "/event/live",
    meta: liveOPHpd1W4LUMeta || {},
    alias: liveOPHpd1W4LUMeta?.alias || [],
    redirect: liveOPHpd1W4LUMeta?.redirect,
    component: () => import("/Users/fahrezibayu/guestify-v2/pages/event/live.vue").then(m => m.default || m)
  },
  {
    name: indexEAfqQoSxq9Meta?.name ?? "guest",
    path: indexEAfqQoSxq9Meta?.path ?? "/guest",
    meta: indexEAfqQoSxq9Meta || {},
    alias: indexEAfqQoSxq9Meta?.alias || [],
    redirect: indexEAfqQoSxq9Meta?.redirect,
    component: () => import("/Users/fahrezibayu/guestify-v2/pages/guest/index.vue").then(m => m.default || m)
  },
  {
    name: indexGFEcs3iam3Meta?.name ?? "index",
    path: indexGFEcs3iam3Meta?.path ?? "/",
    meta: indexGFEcs3iam3Meta || {},
    alias: indexGFEcs3iam3Meta?.alias || [],
    redirect: indexGFEcs3iam3Meta?.redirect,
    component: () => import("/Users/fahrezibayu/guestify-v2/pages/index.vue").then(m => m.default || m)
  },
  {
    name: kehadiranl5deOIyWduMeta?.name ?? "laporan-kehadiran",
    path: kehadiranl5deOIyWduMeta?.path ?? "/laporan/kehadiran",
    meta: kehadiranl5deOIyWduMeta || {},
    alias: kehadiranl5deOIyWduMeta?.alias || [],
    redirect: kehadiranl5deOIyWduMeta?.redirect,
    component: () => import("/Users/fahrezibayu/guestify-v2/pages/laporan/kehadiran.vue").then(m => m.default || m)
  },
  {
    name: indexbsJIm98XtyMeta?.name ?? "pengguna",
    path: indexbsJIm98XtyMeta?.path ?? "/pengguna",
    meta: indexbsJIm98XtyMeta || {},
    alias: indexbsJIm98XtyMeta?.alias || [],
    redirect: indexbsJIm98XtyMeta?.redirect,
    component: () => import("/Users/fahrezibayu/guestify-v2/pages/pengguna/index.vue").then(m => m.default || m)
  },
  {
    name: profile6K5mR8j8ZHMeta?.name ?? "pengguna-profile",
    path: profile6K5mR8j8ZHMeta?.path ?? "/pengguna/profile",
    meta: profile6K5mR8j8ZHMeta || {},
    alias: profile6K5mR8j8ZHMeta?.alias || [],
    redirect: profile6K5mR8j8ZHMeta?.redirect,
    component: () => import("/Users/fahrezibayu/guestify-v2/pages/pengguna/profile.vue").then(m => m.default || m)
  }
]