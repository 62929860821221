import { defineStore } from "pinia";
import axios from "axios";

const baseURLApi = process.env.NODE_ENV === 'production' ? 'https://api-guestifa-v2.tim-open.pro/' : 'https://api-guestifa-v2.tim-open.pro';
// const baseURLApi = process.env.NODE_ENV === 'production' ? 'https://guestifa-api-v2.tim-open.pro/' : 'http://localhost:4000/';

const serverApi = axios.create({
  baseURL: baseURLApi,
});

const accessToken = localStorage.getItem("accessToken");

const cors = {
  withCredentials: true,
  headers: {
    'Authorization': `${accessToken}`
  }
};
const cors2 = {
  withCredentials: true,
  headers: {

  }
};


export const useMainStore = defineStore("main", {
  state: () => ({
    dataUser: {}
  }),
  actions: {
    async login(username: any, password: any) {
      try {
        const response = await serverApi.post("/login", {
          username: username,
          password: password,
        }, cors2);
        return response;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async logout() {
      try {
        const response = await serverApi.get("/logout", cors);
        return response;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getSidebar(token: any) {
      if (localStorage.getItem("accessToken") == null) {
        window.location.reload()
      } else {

        try {
          const response = await serverApi.get("/token/decodeToken", {
            withCredentials: true,
            headers: {
              'Authorization': `${token}`
            }
          });
          this.dataUser = response.data.data
          return response;
        } catch (error) {
          console.error(error);
          throw error;
        }
      }
    },
    async dataAccount(id: any) {
      if (localStorage.getItem("accessToken") == null) {
        window.location.reload()
      } else {
        try {
          const response = await serverApi.get("/account/" + id, cors);
          return response;
        } catch (error) {
          console.error(error);
          throw error;
        }
      }
    },
    async getProvinsi() {
      try {
        const response = await axios.get("https://www.emsifa.com/api-wilayah-indonesia/api/provinces.json");

        return response;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getKota(idProvinsi: any) {
      try {
        const response = await axios.get(`https://www.emsifa.com/api-wilayah-indonesia/api/regencies/${idProvinsi}.json`);

        return response;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    // event
    async getDataEvent() {
      if (localStorage.getItem("accessToken") == null) {
        window.location.reload()
      } else {
        try {
          const response = await serverApi.get("/event", {
            headers: {
              'Authorization': `${accessToken}`
            }
          });
          return response;
        } catch (error) {
          console.error(error);
          throw error;
        }
      }
    },
    async getSearchEvent(tgl1: any, tgl2: any) {
      if (localStorage.getItem("accessToken") == null) {
        window.location.reload()
      } else {
        try {
          const response = await serverApi.post("/event/laporan", {
            tgl1: tgl1,
            tgl2: tgl2
          }, cors);

          return response;
        } catch (error) {
          console.error(error);
          throw error;
        }
      }
    },
    async editEvent(id: any) {
      if (localStorage.getItem("accessToken") == null) {
        window.location.reload()
      } else {
      }
      try {
        const response = await serverApi.get("/event/edit/" + id, cors);
        return response;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async deleteEvent(id: any) {
      if (localStorage.getItem("accessToken") == null) {
        window.location.reload()
      } else {
        try {
          const response = await serverApi.get("/event/delete/" + id, cors);
          return response;
        } catch (error) {
          console.error(error);
          throw error;
        }
      }
    },
    async saveEvent(
      namaEvent: any, 
      tglEvent: any, 
      namaEventOrganizer: any, 
      provinsi: any, 
      kota: any, 
      alamatEvent: any, 
      namaClient: any, 
      jamAwal: any, 
      jamAkhir: any, 
      event_is_selesai: any,
      undangan: any,
      jenis_undangan: any,
      foto: any,
      fotopria:any,
      fotowanita:any,
      fotogallery:any,
      namapria:any,
      namawanita:any,
      katamutiara:any,
      asalkatamutiara : any,
      bank1:any,
      norek1:any,
      bank2:any,
      norek2 : any,
      priaanakke:any,
      wanitaanakke:any,
      namabapakpria:any,
      namaibupria : any,
      namabapakwanita:any,
      namaibuwanita : any,
      musik: any,
      lokasiacara: any,
      urlmapacara: any,
      fotoBackground: any,
      jenisThema: any,
      namabapak: any,
      namaibu: any,
      dresscode: any,
      kutipan: any,
      asalkutipan: any,
      embedmap: any,
      fotodecoration: any,
      namaprialengkap: any,
      namawanitalengkap: any,
      lokasiakad: any,
      alamatakad: any,
      urlalamatakad: any,
      lokasiresepsi: any,
      alamatresepsi: any,
      urlalamatresepsi: any,
      tanggalAkad: any,
      jamAkad: any,
      tanggalResepsi: any,
      jamResepsi: any
      ) {
      if (localStorage.getItem("accessToken") == null) {
        window.location.reload()
      } else {
        try {
          const response = await serverApi.postForm("/event/save",
            {
              namaEvent: namaEvent,
              tglEvent: tglEvent,
              namaEventOrganizer: namaEventOrganizer,
              provinsi: provinsi,
              kota: kota,
              alamatEvent: alamatEvent,
              namaClient: namaClient,
              // jumlahTamu: jumlahTamu,
              jamAwal: jamAwal,
              jamAkhir: jamAkhir,
              event_is_selesai: event_is_selesai,
              undangan: undangan,
              jenis_undangan: jenis_undangan,
              namapria: namapria,
              namawanita: namawanita,
              kata_mutiara: katamutiara,
              asal_kata_mutiara: asalkatamutiara,
              bank1: bank1,
              norek1: norek1,
              bank2: bank2,
              norek2: norek2,
              pria_anak_ke: priaanakke,
              wanita_anak_ke: wanitaanakke,
              nama_bapak_pria: namabapakpria,
              nama_ibu_pria: namaibupria,
              nama_bapak_wanita: namabapakwanita,
              nama_ibu_wanita: namaibuwanita,
              fotopria: fotopria,
              fotowanita: fotowanita,
              foto:foto,
              fotogallery: fotogallery,
              musik:musik,
              lokasiacara: lokasiacara,
              urlmapacara: urlmapacara,
              fotoBackground: fotoBackground,
              jenis_thema: jenisThema,
              namabapak: namabapak,
              namaibu: namaibu,
              dresscode: dresscode,
              kutipan: kutipan,
              asalkutipan: asalkutipan,
              embedmap: embedmap,
              fotodecoration: fotodecoration,
              namaprialengkap: namaprialengkap,
              namawanitalengkap: namawanitalengkap,
              lokasiakad: lokasiakad,
              alamatakad: alamatakad,
              urlalamatakad: urlalamatakad,
              lokasiresepsi: lokasiresepsi,
              alamatresepsi: alamatresepsi,
              urlalamatresepsi: urlalamatresepsi,
              tanggalAkad: tanggalAkad,
              jamAkad: jamAkad,
              tanggalResepsi: tanggalResepsi,
              jamResepsi: jamResepsi
            }, cors);
          return response;
        } catch (error) {
          console.error(error);
          throw error;
        }
      }
    },
    async updateEvent(
      namaEvent: any, 
      tglEvent: any, 
      namaEventOrganizer: any, 
      provinsi: any, kota: any, 
      alamatEvent: any, 
      namaClient: any, 
      jamAwal: any, 
      jamAkhir: any, 
      event_is_selesai: any, 
      idEvent: any,
      undangan: any,
      jenis_undangan: any,
      foto: any,
      fotopria:any,
      fotowanita:any,
      fotogallery:any,
      namapria:any,
      namawanita:any,
      katamutiara:any,
      asalkatamutiara : any,
      bank1:any,
      norek1:any,
      bank2:any,
      norek2 : any,
      priaanakke:any,
      wanitaanakke:any,
      namabapakpria:any,
      namaibupria : any,
      namabapakwanita:any,
      namaibuwanita : any,
      musik: any,
      lokasiacara: any,
      urlmapacara: any,
      fotoBackground: any,
      jenisThema: any,
      namabapak: any,
      namaibu: any,
      dresscode: any,
      kutipan: any,
      asalkutipan: any,
      embedmap: any,
      fotodecoration: any,
      namaprialengkap: any,
      namawanitalengkap: any,
      lokasiakad: any,
      alamatakad: any,
      urlalamatakad: any,
      lokasiresepsi: any,
      alamatresepsi: any,
      urlalamatresepsi: any,
      tanggalAkad: any,
      jamAkad: any,
      tanggalResepsi: any,
      jamResepsi: any
      ) {
      if (localStorage.getItem("accessToken") == null) {
        window.location.reload()
      } else {
        try {
          const response = await serverApi.postForm("/event/update",
            {
              namaEvent: namaEvent,
              tglEvent: tglEvent,
              namaEventOrganizer: namaEventOrganizer,
              provinsi: provinsi,
              kota: kota,
              alamatEvent: alamatEvent,
              namaClient: namaClient,
              // jumlahTamu: jumlahTamu,
              jamAwal: jamAwal,
              jamAkhir: jamAkhir,
              event_is_selesai: event_is_selesai,
              idEvent: idEvent,
              undangan: undangan,
              jenis_undangan: jenis_undangan,
              namapria: namapria,
              namawanita: namawanita,
              kata_mutiara: katamutiara,
              asal_kata_mutiara: asalkatamutiara,
              bank1: bank1,
              norek1: norek1,
              bank2: bank2,
              norek2: norek2,
              pria_anak_ke: priaanakke,
              wanita_anak_ke: wanitaanakke,
              nama_bapak_pria: namabapakpria,
              nama_ibu_pria: namaibupria,
              nama_bapak_wanita: namabapakwanita,
              nama_ibu_wanita: namaibuwanita,
              fotopria: fotopria,
              fotowanita: fotowanita,
              foto:foto,
              fotogallery: fotogallery,
              musik:musik,
              lokasiacara: lokasiacara,
              urlmapacara: urlmapacara,
              fotoBackground: fotoBackground,
              jenis_thema: jenisThema,
              namabapak: namabapak,
              namaibu: namaibu,
              dresscode: dresscode,
              kutipan: kutipan,
              asalkutipan: asalkutipan,
              embedmap: embedmap,
              fotodecoration: fotodecoration,
              namaprialengkap: namaprialengkap,
              namawanitalengkap: namawanitalengkap,
              lokasiakad: lokasiakad,
              alamatakad: alamatakad,
              urlalamatakad: urlalamatakad,
              lokasiresepsi: lokasiresepsi,
              alamatresepsi: alamatresepsi,
              urlalamatresepsi: urlalamatresepsi,
              tanggalAkad: tanggalAkad,
              jamAkad: jamAkad,
              tanggalResepsi: tanggalResepsi,
              jamResepsi: jamResepsi
            }, cors);
          return response;
        } catch (error) {
          console.error(error);
          throw error;
        }
      }
    },
    // guest
    async getDataGuest(idEvent: any,page:any,limit:any) {
      if (localStorage.getItem("accessToken") == null) {
        window.location.reload()
      } else {
        try {
          const response = await serverApi.get("/guest/" + idEvent + '?page='+page+'&limit='+limit, cors);

          return response;
        } catch (error) {
          console.error(error);
          throw error;
        }
      }
    },
    async editGuest(id: any) {
      if (localStorage.getItem("accessToken") == null) {
        window.location.reload()
      } else {
        try {
          const response = await serverApi.get("/guest/edit/" + id, cors);
          return response;
        } catch (error) {
          console.error(error);
          throw error;
        }
      }
    },
    async deleteGuest(id: any) {
      if (localStorage.getItem("accessToken") == null) {
        window.location.reload()
      } else {
        try {
          const response = await serverApi.get("/guest/delete/" + id, cors);
          return response;
        } catch (error) {
          console.error(error);
          throw error;
        }
      }
    },
    async saveGuest(namaGuest: any, noHpGuest: any, emailGuest: any, noMeja: any, seat: any, vip: any, nobarcode:any, idEvent: any) {
      if (localStorage.getItem("accessToken") == null) {
        window.location.reload()
      } else {
        try {
          const response = await serverApi.post("/guest/save",
            {
              namaGuest: namaGuest,
              noHpGuest: noHpGuest,
              emailGuest: emailGuest,
              noMeja: noMeja,
              seat: seat,
              vip: vip,
              nobarcode: nobarcode,
              idEvent: idEvent
            }, cors);
          return response;
        } catch (error) {
          console.error(error);
          throw error;
        }
      }
    },
    async updateGuest(namaGuest: any, noHpGuest: any, emailGuest: any, noMeja: any, seat: any,vip: any, idGuest: any) {
      if (localStorage.getItem("accessToken") == null) {
        window.location.reload()
      } else {
        try {
          const response = await serverApi.post("/guest/update",
            {
              namaGuest: namaGuest,
              noHpGuest: noHpGuest,
              emailGuest: emailGuest,
              noMeja: noMeja,
              seat: seat,
              vip:vip,
              idGuest: idGuest
            }, cors);
          return response;
        } catch (error) {
          console.error(error);
          throw error;
        }
      }
    },
    async checkAbsen(id: any, idEvent: any, tgl: any) {
      if (localStorage.getItem("accessToken") == null) {
        window.location.reload()
      } else {
        try {
          const response = await serverApi.post("/guest/checkAbsen",
            {
              id: id,
              idEvent: idEvent,
              tgl: tgl
            }, cors);
          return response;
        } catch (error) {
          console.error(error);
          throw error;
        }
      }
    },
    async cancelAbsen(id: any) {
      if (localStorage.getItem("accessToken") == null) {
        window.location.reload()
      } else {
        try {
          const response = await serverApi.post("/guest/cancelAbsen",
            {
              id: id
            }, cors);
          return response;
        } catch (error) {
          console.error(error);
          throw error;
        }
      }
    },
    // pengguna
    async getDataPengguna() {
      if (localStorage.getItem("accessToken") == null) {
        window.location.reload()
      } else {
        try {
          const response = await serverApi.get("/pengguna", cors);

          return response;
        } catch (error) {
          console.error(error);
          throw error;
        }
      }
    },
    async deletePengguna(id: any) {
      if (localStorage.getItem("accessToken") == null) {
        window.location.reload()
      } else {
        try {
          const response = await serverApi.get("/pengguna/delete/" + id, cors);
          return response;
        } catch (error) {
          console.error(error);
          throw error;
        }
      }
    },
    async savePengguna(namaPengguna: any, username: any, password: any) {
      if (localStorage.getItem("accessToken") == null) {
        window.location.reload()
      } else {
        try {
          const response = await serverApi.post("/pengguna/save",
            {
              namaPengguna: namaPengguna,
              username: username,
              password: password
            }, cors);
          return response;
        } catch (error) {
          console.error(error);
          throw error;
        }
      }
    },
    async updateProfileUser(username: any, password: any, namaPengguna: any, idUser: any) {
      if (localStorage.getItem("accessToken") == null) {
        window.location.reload()
      } else {
        try {
          const response = await serverApi.post("/pengguna/update/profile",
            {
              username: username,
              password: password,
              namaPengguna: namaPengguna,
              idUser: idUser
            }, cors);
          return response;
        } catch (error) {
          console.error(error);
          throw error;
        }
      }
    },
    // kehadiran wedding
    async getDataKehadiran(idEvent:any) {
      if (localStorage.getItem("accessToken") == null) {
        window.location.reload()
      } else {
        try {
          const response = await serverApi.get("/kehadiran/"+idEvent, cors);

          return response;
        } catch (error) {
          console.error(error);
          throw error;
        }
      }
    },
  },
});