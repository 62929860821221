import { useLoading } from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
const loading = useLoading({
    loader: "dots",
    color: "#2f80ed"
});
export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.use(useLoading);

    return {
        provide: { loading },
    };
});