import revive_payload_client_4sVQNw7RlN from "/Users/fahrezibayu/guestify-v2/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/Users/fahrezibayu/guestify-v2/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/Users/fahrezibayu/guestify-v2/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/Users/fahrezibayu/guestify-v2/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/Users/fahrezibayu/guestify-v2/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/Users/fahrezibayu/guestify-v2/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/Users/fahrezibayu/guestify-v2/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/Users/fahrezibayu/guestify-v2/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import quasar_plugin_G9rpXFQbul from "/Users/fahrezibayu/guestify-v2/.nuxt/quasar-plugin.mjs";
import provide_4gUmUdUSZU from "/Users/fahrezibayu/guestify-v2/node_modules/nuxt-quasar-ui/dist/runtime/provide.mjs";
import chunk_reload_client_UciE0i6zes from "/Users/fahrezibayu/guestify-v2/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import loading_overlay_3ErHCTFcnc from "/Users/fahrezibayu/guestify-v2/plugins/loading-overlay.ts";
import pinia_48xmdi2HHl from "/Users/fahrezibayu/guestify-v2/plugins/pinia.ts";
import primevue_7rYYRZQLyx from "/Users/fahrezibayu/guestify-v2/plugins/primevue.ts";
import vue3_toastify_OGYNDsiW9E from "/Users/fahrezibayu/guestify-v2/plugins/vue3-toastify.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  quasar_plugin_G9rpXFQbul,
  provide_4gUmUdUSZU,
  chunk_reload_client_UciE0i6zes,
  loading_overlay_3ErHCTFcnc,
  pinia_48xmdi2HHl,
  primevue_7rYYRZQLyx,
  vue3_toastify_OGYNDsiW9E
]