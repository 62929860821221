import { defineNuxtPlugin } from "#app";
import PrimeVue from "primevue/config";
import Button from "primevue/button";
import InputText from 'primevue/inputtext';
import Password from 'primevue/password';
import Calendar from 'primevue/calendar';
import Paginator from 'primevue/paginator';
import InputNumber from 'primevue/inputnumber';
import FileUpload from 'primevue/fileupload';
import Avatar from 'primevue/avatar';
import AvatarGroup from 'primevue/avatargroup';   //Optional for grouping
import InputMask from 'primevue/inputmask';
import ConfirmDialog from 'primevue/confirmdialog';
import Toast from 'primevue/toast';
import ConfirmationService from 'primevue/confirmationservice';



export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.use(PrimeVue, { ripple: true });
    nuxtApp.vueApp.component("Button", Button);
    nuxtApp.vueApp.component("InputText", InputText);
    nuxtApp.vueApp.component("Password", Password);
    nuxtApp.vueApp.component("Calendar", Calendar);
    nuxtApp.vueApp.component("Paginator", Paginator);
    nuxtApp.vueApp.component("InputNumber", InputNumber);
    nuxtApp.vueApp.component("FileUpload", FileUpload);
    nuxtApp.vueApp.component("Avatar", Avatar);
    nuxtApp.vueApp.component("AvatarGroup", AvatarGroup);
    nuxtApp.vueApp.component("InputMask", InputMask);
    nuxtApp.vueApp.component("ConfirmDialog", ConfirmDialog);
    nuxtApp.vueApp.component("Toast", Toast);
    nuxtApp.vueApp.component("ConfirmationService", ConfirmationService);
    // tambahkan komponen lain yang Anda butuhkan di sini
});
